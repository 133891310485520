import PropTypes from "prop-types";
import React from "react";

const MemberBlurb = ({ summary, hobbies, game, quote }) => (
	<div className="memberBlurb">
		<BlurbRow label="Summary" content={summary} />
		<BlurbRow label="Hobbies" content={hobbies} />
		<BlurbRow label="Game" content={game} />
		<BlurbRow label="Quote" content={quote} />
	</div>
);

const BlurbRow = ({ label, content }) => (
	<div className="blurbRow">
		<p className="blurbLabel">{label}:</p>
		<p className="blurbContent">{content}</p>
	</div>
);

BlurbRow.propTypes = {
	label: PropTypes.string,
	content: PropTypes.string,
};

MemberBlurb.propTypes = {
	summary: PropTypes.string,
	hobbies: PropTypes.string,
	game: PropTypes.string,
	quote: PropTypes.string,
};

MemberBlurb.defaultProps = {
	siteTitle: "",
	hobbies: "",
	game: "",
	quote: "",
};

export default MemberBlurb;
