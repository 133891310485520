import React from "react";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";

import {
	ScrollToTopOnMount,
	SectionsContainer,
	Section,
	Header,
	Footer,
} from "react-fullpage";

import MemberBlurb from "../components/memberBlurb";
import Image from "../components/image";
import "../styles/main.scss";
import beta_gif from "../images/Beta_large.gif";
import marmalade_gif from "../images/Marmalade_large.gif";
import sunbro_gif from "../images/Sunbro_large.gif";
import floyd_gif from "../images/Floyd_large.gif";
import irondoll_gif from "../images/Irondoll_large.gif";
import play_button from "../images/youtube.png";

export default class fullPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			temp: "",
		};
	}

	// exports.onRouteUpdate = ({ location, prevLocation }) => {
	// 	console.log("new pathname", location.pathname);
	// }

	componentDidMount() {
		console.log("in the mount!");
		var url = window.location.href;
		var key = "#";
		var index = url.indexOf(key);
		var section = url.substring(index + key.length);
		window.addEventListener("load", () =>
			setTimeout(scrollTo(section), 300)
		);

		// var end = url.indexOf("/#");
		// if (end >= 0) {
		// 	var redirect = url.substring(0, end);
		// 	var begin = redirect.lastIndexOf("/");
		// 	redirect = redirect.substring(begin) + "/";

		// 	navigate(redirect);
		// }
	}

	render() {
		var options = {
			sectionClassName: "section",
			anchors: ["title", "about", "members1", "members2", "members3"],
			scrollBar: false,
			navigation: false,
			verticalAlign: true,
			arrowNavigation: true,
		};

		var s1 = (
			<div className="sectionTitle">
				<div className="flexDiv">
					<h1 className="title right">team</h1>
					<h1 className="title left">defeat</h1>
				</div>
				<div
					className="arrow-holder"
					onClick={() => (document.location.href = "#about")}
				>
					<p className="down-arrow">\/</p>
				</div>
			</div>
		);

		var s2 = (
			<div className="sectionDiv about">
				<div className="intro">
					<h1>Oh, hello there!</h1>
					<p className="pleft">Do you appreciate good ribbing?</p>
					<p className="pright">
						Do you sometimes vomit while hiccuping?
					</p>
					<p className="pleft">Can you speak in a myriad accents?</p>
					<p className="pright">
						Can you meme with the best of them?
					</p>
					<p className="">If so, you might be us! Weird...</p>
					<p className="">
						We are TEAM DEFEAT, an exceptional group of somewhat
						competent gamers. What we lack in win rate we make up
						for in comradery.
					</p>
					<p />
					<a href="#members1">Meet us :-)</a>
				</div>
			</div>
		);

		var Caleb = (
			<div className="member">
				<div className="memberBox">
					<div className="memberBio">
						<div className="memberHeader">
							<h1 className="memberName">Cheesuz</h1>
							<img className="sprite" src={beta_gif} alt="beta" />
							<a
								href="https://www.youtube.com/embed/PNfMX3daD0M"
								target="blank"
							>
								<img
									className="mediaLink"
									src={play_button}
									alt="media"
								/>
							</a>
						</div>
						<MemberBlurb
							summary="Every member of Team Defeat has a reserved
									seat on this man's shoulders. A king of all
									trades, Cheesuz excels at every aspect."
							hobbies={"music, dad jokes, large bowls of salad"}
							game={"not Destiny"}
							quote={"*inhales* \"I need a snack\""}
						/>
					</div>
					<div className="memberMedia">
						<iframe
							className="memberVideo"
							src="https://www.youtu.be/PNfMX3daD0M"
							frameBorder="0"
							allowFullScreen={true}
							title="caleb's vid"
						/>
					</div>
				</div>
			</div>
		);

		var Kyle = (
			<div className="member">
				<div className="memberBox">
					<div className="memberMedia">
						<iframe
							className="memberVideo"
							src="https://www.youtube.com/embed/4f6hjBTwe64"
							frameBorder="0"
							allowFullScreen={true}
							title="caleb's vid"
						/>
					</div>
					<div className="memberBio">
						<div className="memberHeader">
							<h1 className="memberName">Sunbro</h1>
							<img
								className="sprite"
								src={sunbro_gif}
								alt="sunbro"
							/>
							<a
								href="https://www.youtube.com/embed/4f6hjBTwe64"
								target="blank"
							>
								<img
									className="mediaLink"
									src={play_button}
									alt="media"
								/>
							</a>
						</div>
						<MemberBlurb
							summary="The squad's bard, ever optimistic and
							gathering us together. Despite crazy work and school
							schedules, he always has time for Team Defeat."
							hobbies={"destiny, apple, lore and news updates"}
							game={"Destiny"}
							quote={"Alright, we still got this!"}
						/>
					</div>
				</div>
			</div>
		);

		var Anna = (
			<div className="member">
				<div className="memberBox">
					<div className="memberBio">
						<div className="memberHeader">
							<h1 className="memberName">Irondoll</h1>
							<img
								className="sprite"
								src={irondoll_gif}
								alt="irondoll"
							/>
							<a
								href="https://www.youtube.com/embed/V24j_n7bHn0"
								target="blank"
							>
								<img
									className="mediaLink"
									src={play_button}
									alt="media"
								/>
							</a>
						</div>
						<MemberBlurb
							summary="The sweet mother of Team Defeat if your
							mother has the mouth of a sailor. Clutchest of
							supports. Doesn't want Mercy but won't let you
							have her."
							hobbies={
								"ballet, complaining about developers, la croix"
							}
							game={"making Cheezus bring her a la croix"}
							quote={"F*CK YOU, B*TCH!!!"}
						/>
					</div>
					<div className="memberMedia">
						<iframe
							className="memberVideo"
							src="https://www.youtube.com/embed/V24j_n7bHn0"
							frameBorder="0"
							allowFullScreen={true}
							title="anna's vid"
						/>
					</div>
				</div>
			</div>
		);

		var Taylor = (
			<div className="member">
				<div className="memberBox">
					<div className="memberMedia">
						<iframe
							className="memberVideo"
							src="https://www.youtube.com/embed/4f6hjBTwe64"
							frameBorder="0"
							allowFullScreen={true}
							title="taylor's vid"
						/>
					</div>
					<div className="memberBio">
						<div className="memberHeader">
							<h1 className="memberName">Marmalade</h1>
							<img
								className="sprite"
								src={marmalade_gif}
								alt="marmalade"
							/>
							<a
								href="https://www.youtube.com/embed/4f6hjBTwe64"
								target="blank"
							>
								<img
									className="mediaLink"
									src={play_button}
									alt="media"
								/>
							</a>
						</div>
						<MemberBlurb
							summary="Absolutely, without a doubt, HATES dying.
							Sick beats for dayz. Prefers to not use the scope as
							widow."
							hobbies={
								"memes, embarrassing Floyd, embarrassing Sunbro"
							}
							game={"Pretending to have never seen the Office"}
							quote={"... shit ..."}
						/>
					</div>
				</div>
			</div>
		);

		var John = (
			<div className="member">
				<div className="memberBox">
					<div className="memberBio">
						<div className="memberHeader">
							<h1 className="memberName">Floyd</h1>
							<img
								className="sprite"
								src={floyd_gif}
								alt="floyd"
							/>
							<a
								href="https://www.youtube.com/embed/MilLshjPBXs"
								target="blank"
							>
								<img
									className="mediaLink"
									src={play_button}
									alt="media"
								/>
							</a>
						</div>
						<MemberBlurb
							summary="Simultaneously the nicest and saltiest
							member of Team Defeat. Wannabe tank main rocking
							Moira. Such a sore lose. Like, seriously."
							hobbies={"torb cosplay, complaining about reaper"}
							game={"One in which he wins"}
							quote={"Woooooo doggy!!"}
						/>
					</div>
					<div className="memberMedia">
						<iframe
							className="memberVideo"
							src="https://www.youtube.com/embed/MilLshjPBXs"
							frameBorder="0"
							allowFullScreen={true}
							title="john's vid"
						/>
					</div>
				</div>
			</div>
		);

		var members1 = (
			<div className="sectionDiv members">
				<h1 className="label">MEMBERS</h1>
				<div className="memberArea">
					{Caleb}
					{Kyle}
				</div>
			</div>
		);

		var members2 = (
			<div className="sectionDiv members">
				<h1 className="label">MEMBERS</h1>
				<div className="memberArea">
					{Anna}
					{Taylor}
				</div>
			</div>
		);

		var members3 = (
			<div className="sectionDiv members">
				<h1 className="label">MEMBERS</h1>
				<div className="memberArea">{John}</div>
				<a href="#title">Go to the top!</a>
			</div>
		);

		return (
			<div>
				{/* <Header>
					<a href="#title">Section One</a>
					<a href="#about">Section Two</a>
					<a href="#members">Section Three</a>
				</Header> */}
				{/* <Footer>
					<a href="">Dcoumentation</a>
					<a href="">Example Source</a>
					<a href="">About</a>
				</Footer> */}
				<ScrollToTopOnMount />
				<SectionsContainer className="container" {...options}>
					<Section>{s1}</Section>
					<Section>{s2}</Section>
					<Section color="#7BAABE">{members1}</Section>
					<Section color="#7BAABE">{members2}</Section>
					<Section color="#7BAABE">{members3}</Section>
				</SectionsContainer>
			</div>
		);
	}
}

function scrollTo(section) {
	switch (section) {
	case "members":
		window.scroll(0, window.innerHeight * 2);
		break;
	case "about":
		window.scroll(0, window.innerHeight);
		break;
	default:
		console.log("doing nothing");
	}
}

fullPage.propTypes = {
	exampleString: PropTypes.string,
};

// meteor thing :-(
// export default createContainer(() => {
// 	return {
// 		exampleString: "test"
// 	};
// }, fullPage);
